import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { getAllFilters, getAllRewards } from "../packages/api/store";
import { IFilter, IRewards } from "../packages/interface/rewards";

import { useAppContext } from "../context";
import { CartDataModal } from "../components/cart";
import { Filter } from "../components/shared";
import { Tracker } from "../lib/sentry";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { setError } from "../store/user/userSlice";
import { Rewards } from "../components/reward";
import { setFilters, updateFilter } from "../store/filters/filterSlice";
import { brands } from "../packages/utils/filter";
import { useDebounce } from "../packages/hooks";

const Home = ({
  balance,
  setBalance,
  reedemNow,
  isInitialCartLoading,
}: {
  balance: number;
  setBalance: Dispatch<SetStateAction<number>>;
  reedemNow: () => void;
  isInitialCartLoading: boolean;
}) => {
  const {
    search,
    isModal,
    setIsShowReedemActiveModal,
    originalPointsRange,
    token,
    // updateFilters,
  } = useAppContext();
  const dispatch = useDispatch<AppDispatch>();
  const { filters } = useSelector((state: RootState) => state.filter);
  const [isFiltersOn, setIsFiltersOn] = useState<boolean>(false);
  const [filterType, setFilterType] = useState<"filter" | "sort" | null>(null);
  const [rewards, setRewards] = useState<any[]>([]);
  const [isloading, setIsloading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [filtersData, setFiltersData] = useState<any>(null);
  // const [filters, setFilters] = useState<IFilter>({
  //   brands: [],
  //   categories: [],
  // });

  const [minValue, setMinValue] = useState<number>(0);
  const [maxValue, setMaxValue] = useState<number>(0);
  const minDebounce = useDebounce(minValue, 1000);
  const maxDebounce = useDebounce(maxValue, 300);
  const [sort, setSort] = useState<string>('-points');
  const [currentShortType, setCurrentShortType] = useState<string>('-points')
  const [filterDataLoading, setFilterDataLoading] = useState<boolean>(false);

  const [link, setLink] = useState<any>();

  // const [isMobileReedem, setIsMobileReedem] = useState<boolean>(false);
  const [filterValueChangeLoading, setFilterValueChangeLoading] =
    useState<boolean>(false);

  const [applyLoadRewards, setApplyLoadRewards] = useState<boolean>(true);

  const tracker = new Tracker();



  const handleChange = useCallback((e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    if (e.target.checked) {
      if (name === "brands") {
        const newBrands = [...filters.brands, value];
        dispatch(setFilters({ brands: [value] }));
      }

      if (name === "category") {
        const newCategory = [...filters.categories, value];
        dispatch(setFilters({ categories: newCategory }));
      }
      if (name === 'sort') {
        console.log(value)
        setCurrentShortType(value)
        setSort(value)
      }
    } else {
      if (name === "brands") {
        const newBrands = filters.brands.filter((item) => item !== value);
        dispatch(setFilters({ brands: newBrands }));
        // setFilters({ ...filters, brands: newBrands });
      }
      if (name === "category") {
        const newCategory = filters.categories.filter((item) => item !== value);
        // setFilters({ ...filters, categories: newCategory });
        dispatch(setFilters({ categories: newCategory }));
      }
      if (name === 'sort') {
        console.log(value)
        setCurrentShortType(value)
        setSort(value)
      }
    }
  }, []);

  const loadRewards = (token: string, page: number, pagefilters?: {}) => {
    setIsloading(true);
    getAllRewards(token, page, pagefilters)
      .then((res: any) => {
        setLink(res.data.data.links.next);
        const newRewards = res?.data.data.results;

        setRewards((prevRewards: any) => {
          if (page === 1) {
            return newRewards;
          } else {
            return [...prevRewards, ...newRewards];
          }
        });
        setIsloading(false);
        setFilterValueChangeLoading(false);
        setApplyLoadRewards(false);
      })
      .catch((error: any) => {
        const message: string = error?.response?.data?.data?.message;
        if (
          error?.response.status === 401 ||
          (error?.response.status === 500 &&
            Object.keys(error?.response?.data?.data?.error ?? {}).length == 0)
        ) {
          dispatch(setError({ msg: "Invalid Token", isError: true }));

          setIsloading(false);
          setFilterValueChangeLoading(false);
        } else if (message.toLowerCase().search("page") >= 0) {
          setIsloading(false);
          setFilterValueChangeLoading(false);
        } else {
          dispatch(
            setError({ msg: "Something went wrong Try again", isError: true })
          );
          setIsloading(false);
          setFilterValueChangeLoading(false);
        }
        setApplyLoadRewards(false);
      });
  };

  const loadFilters = (query?: any) => {
    getAllFilters(token, query)
      .then((response: any) => {
        const newFiltersData = response?.data.data.data;

        setFiltersData(newFiltersData);
        setFilterDataLoading(false);
        setMinValue(newFiltersData?.points.min);
        setMaxValue(newFiltersData?.points.max);
      })
      .catch((error: any) => {
        if (error?.response.status === 401) {
          dispatch(setError({ msg: "Invalid Token", isError: true }));
          setFilterDataLoading(false);
        } else {
          dispatch(
            setError({ msg: "Something went wrong Try again", isError: true })
          );
          setFilterDataLoading(false);
        }
      });
  };

  const clearFilters = useCallback(
    (type: string) => {
      if (type === "all") {
        const newFilters = {
          brands: [],
          categories: [],
        };
        loadFilters();
        dispatch(updateFilter(newFilters));
        setSort('-points');
        setMaxValue(0);
        setMinValue(0);
      } else if (type === "brand") {
        dispatch(updateFilter({ categories: filters.categories, brands: [] }));
      } else if (type === "category") {
        dispatch(updateFilter({ brands: filters.brands, categories: [] }));
      } else if (type === "range") {
        setMaxValue(filtersData?.points?.max);
        setMinValue(filtersData?.points?.min);
      } else if (type === "sort") {
        setSort('-points');
      }
    },
    [filtersData]
  );

  const applyMobileFilters = useCallback((fa: any, fp: any) => {
    dispatch(updateFilter(fa));
    setMaxValue(fp.mobileMaxValue);
    setMinValue(fp.mobileMinValue);
  }, []);

  useEffect(() => {
    const { brands, categories } = filters;
    tracker.visitedSite();
    setFilterDataLoading(true);
    loadFilters();
  }, []);

  // initial load rewards
  useEffect(() => {
    const { brands, categories } = filters;
    let apiFilters = {};

    // Sort
    if (sort !== null) {
      apiFilters["sort"] = sort;
    }
    // PriceRange
    if (minValue != 0 || maxValue != 0) {
      apiFilters["points_range"] = [minValue, maxValue];
    }
    // Search
    if (search.length > 0) {
      apiFilters["query"] = search;
    }
    // Brands
    if (brands.length > 0) {
      apiFilters["brands"] = brands;
    }
    //Categories
    if (categories.length > 0) {
      apiFilters["categories"] = categories;
    }

    if (applyLoadRewards) {
      if (Object.keys(apiFilters).length > 0) {
        loadRewards(token, page, apiFilters);
      } else {
        loadRewards(token, page);
      }
    }
  }, [applyLoadRewards]);

  // load rewards with filters
  useEffect(() => {
    setFilterValueChangeLoading(true);
    let isCancelled = false;
    const { brands, categories } = filters;
    let apiFilters = {};

    // Sort
    if (sort !== null) {
      apiFilters["sort"] = sort;
    }
    // PriceRange
    if (
      minDebounce !== originalPointsRange?.points?.min ||
      maxDebounce !== originalPointsRange?.points?.max
    ) {
      apiFilters["points_range"] = [minDebounce, maxDebounce];
    }
    // Search
    if (search.length > 0) {
      apiFilters["query"] = search;
    }
    // Brands
    if (brands.length > 0) {
      apiFilters["brands"] = brands;
    }
    //Categories
    if (categories.length > 0) {
      apiFilters["categories"] = categories;
    }

    if (Object.keys(apiFilters).length > 0) {
      setRewards([]);
      setPage(1);
      setApplyLoadRewards(true);
    }

    let query: any = {};
    if (categories.length > 0) {
      query["category"] = categories[0];
    } else if (brands.length > 0) {
      query["brand"] = brands[0];
    }

    getAllFilters(token, query)
      .then((res) => {
        const newFiltersData = res?.data.data.data;
        setFiltersData(newFiltersData);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [filters.brands, filters.categories, maxDebounce, minDebounce, sort]);

  useEffect(() => {
    if (!applyLoadRewards) {
      setApplyLoadRewards(true);
    }
  }, [page]);

  useEffect(() => {
    if (!applyLoadRewards) {
      setRewards([]);
      clearFilters('all')
      setPage(1);
      setApplyLoadRewards(true);
    }
  }, [search]);


  return (
    <>
      <main className="px-2 h-[calc(100vh-2.8125rem)]  lg:h-[calc(100vh-4rem)] lg:mt-16 flex flex-col-reverse lg:grid lg:grid-cols-[15rem_calc(100vw-18rem)]  xl:grid-cols-[15rem_calc(100vw-19rem)] gap-6 py-2 z-0 ">
        <Filter
          setCurrentShortType={setCurrentShortType}
          filterType={filterType}
          setFilterType={setFilterType}
          isFiltersOn={isFiltersOn}
          setIsFiltersOn={setIsFiltersOn}
          sort={sort}
          filtersData={filtersData}
          handleChange={handleChange}
          clearFilters={clearFilters}
          maxValue={maxValue}
          setMaxValue={setMaxValue}
          minValue={minValue}
          setMinValue={setMinValue}
          applyMobileFilters={applyMobileFilters}
          filterValueChangeLoading={filterValueChangeLoading}
        />
        <Rewards
          setSort={setSort}
          currentShortType={currentShortType}
          setCurrentShortType={setCurrentShortType}
          setFilterType={setFilterType}
          setIsFiltersOn={setIsFiltersOn}
          handleChange={handleChange}
          rewards={rewards}
          balance={balance || 0}
          token={token}
          loading={isloading}
          setPage={setPage}
          setBalance={setBalance}
          reedemNow={reedemNow}
          filters={filters}
          minValue={minValue}
          maxValue={maxValue}
          sort={sort}
          link={link}
          filterValueChangeLoading={filterValueChangeLoading}
          isInitialCartLoading={isInitialCartLoading}
        />
        {isModal && (
          <CartDataModal
            token={token}
            rewards={rewards}
            setBalance={setBalance}
          />
        )}
      </main>
    </>
  );
};

export default Home;
