import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
  memo,
} from "react";
import { FiSearch } from "react-icons/fi";
import { AiOutlinePlus } from "react-icons/ai";
import { TokenUser, useAppContext } from "../../context";
import { HiShoppingCart } from "react-icons/hi";
import MobileSearchBar from "./MobileSearchBar";
import { Tooltip } from "../../packages/design/Tooltip";
import AccoladezLogo from "../../packages/design/AccoladezLogo";
import { useDebounce } from "../../packages/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../packages/utils/routes";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { setIsRemainingRewardModal } from "../../store/rewards/rewardSlice";

interface NavbarProps {
  points: number;
  reedemNow: () => any;
}

const Navbar: FC<NavbarProps> = memo(
  ({
    points,
    reedemNow
  }) => {
    const { cart } = useSelector((state: RootState) => state.cart)
    const { isReedemActive } = useSelector((state: RootState) => state.reward)
    const dispatch = useDispatch<AppDispatch>()

    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { search, setSearch, setIsShowSearchbar } = useAppContext();
    const [query, setQuery] = useState<string | number>("");
    const debouncedSearch = useDebounce(query, 300);

    const handleChange = (e: any) => {
      setQuery(e.target.value);
    };

    useEffect(() => {
      setSearch(debouncedSearch);
      if (debouncedSearch) {
        navigate(routes.products)
      }
    }, [debouncedSearch]);

    return (
      <nav className="px-2 md:shadow-sm fixed fixedhandled top-0 right-0 bg-white left-0  z-20 flex items-center  justify-between  py-2 h-12 md:h-16  lg:space-x-10">
        <div className=" flex items-center justify-between lg:justify-start space-x-10 xl:space-x-14 w-full lg:w-[70%] xl:w-[60%]">
          <div className="flex items-center space-x-8 ">
            <div
              className="h-12 max-w-[5rem] "
            >
              <img src="/hindu.png" alt="hindulogo" className="h-full w-full" />
            </div>

            <div className="hidden lg:block w-[0.15rem]  h-8 shadow-md rounded-full bg-[#D9D9D9]/60"></div>

            <AccoladezLogo className="max-w-[10rem] hidden lg:flex space-x-1" />
          </div>

          <div
            className={`${pathname === "/cart" ? "hidden " : ""
              } lg:w-[50%] xl:w-[60%] `}
          >
            <form
              onSubmit={(e: any) => e.preventDefault()}
              className={`hidden md:block `}
            >
              <div className=" relative">
                <input
                  data-testid="desktopsearchbar"
                  type="text"
                  value={query}
                  onChange={handleChange}
                  className=".navbar  px-10 py-2 border outline-none text-sm w-96 lg:w-full rounded bg-[#F3F3F3]/40 text-[#28282D]/50 font-semibold"
                  placeholder="Let's find something good for you..."
                />
                <span className="absolute  left-2 top-[0.6rem]">
                  <FiSearch className="text-xl text-filterHeading" />
                </span>
                {query !== "" && (
                  <span className="absolute right-1 top-[0.6rem]">
                    <AiOutlinePlus
                      onClick={() => setQuery("")}
                      className="rotate-45 text-xl text-[#919193]"
                    />
                  </span>
                )}
              </div>
            </form>
          </div>
        </div>

        <div className="flex justify-between  lg:w-[28%] xl:w-[38%]  ">
          {pathname !== "/cart" && (
            <div
              id="balance"
              className={` hidden lg:flex space-x-1 items-center  xl:ml-14`}
            >
              <h2 className="hidden xl:block text-base text-black">Balance:</h2>
              <div className="flex items-center space-x-1">
                <img src="/starcoin.png" alt="starcoin" className="h-6 w-6" />
                <span className="text-lg font-medium">{points}</span>
              </div>
            </div>
          )}

          {pathname !== "/cart" && (
            <div className=" hidden lg:flex  space-x-3   ">
              <Tooltip tooltipText="Cart" point="center">
                <div
                  id="cart"
                  data-tg-tour='A button' data-tg-order="0"
                  onClick={() => {
                    navigate(routes.cart)
                  }}
                  className="flex items-center space-x-2  rounded px-3 cursor-pointer py-1 "
                >
                  <div className="w-max relative ">
                    <HiShoppingCart className="text-3xl " />
                    {(pathname === "/products" || "/") && cart.length > 0 && (
                      <div className="absolute -top-[calc(1rem/2)] -right-[calc(1rem/2)] text-white b rounded-full bg-[#ff0000] flex items-center justify-center w-4 h-4 text-[10px] font-medium ">
                        {cart?.length}
                      </div>
                    )}
                  </div>
                </div>
              </Tooltip>
              <button
                id="reedem"
                onClick={() => {
                  if (points === 0) {
                    dispatch(setIsRemainingRewardModal(false))
                    reedemNow();
                  } else {
                    dispatch(setIsRemainingRewardModal(true))
                    reedemNow();
                  }
                }}
                disabled={!isReedemActive}
                className={`${isReedemActive
                  ? "bg-[rgba(242,78,0)]"
                  : "bg-[rgba(242,78,0,0.4)]"
                  } text-white w-max text-center  rounded px-4 text-sm  hidden md:block `}
              >
                Reedem
              </button>
            </div>
          )}
        </div>
        <div className="h-[0.1rem] bg-[#D9D9D9] "></div>

        {/* *******for mobile responsive ***********  */}
        <div
          className={`${pathname === "/cart" ? "hidden" : ""
            } flex space-x-3 md:hidden  mr-2`}
        >
          <FiSearch
            id="mobilesearchbar"
            onClick={() => setIsShowSearchbar(true)}
            className="text-3xl text-filterHeading"
          />
          <div
            id="mobilecart"
            onClick={() => {
              navigate(routes.cart)
            }}
            className="relative "
          >
            <HiShoppingCart className="text-3xl " />
            {cart.length > 0 && (
              <div className="absolute -top-[calc(1rem/2)] -right-[calc(1rem/2)] text-white bg-red-500 rounded-full  flex items-center justify-center w-4 h-4 text-[10px] font-medium  ">
                <p className="bg-red-500 rounded-full">{cart?.length}</p>
              </div>
            )}
          </div>
        </div>

        <MobileSearchBar />

      </nav>
    );
  }
);

export default Navbar;
