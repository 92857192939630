import {
  useEffect,
  useState, memo
} from "react";
import { FiSearch } from "react-icons/fi";
import { useAppContext } from "../../context";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useDebounce } from "../../packages/hooks";

const MobileSearchBar = memo(() => {
  const { setSearch, isShowSearchbar, setIsShowSearchbar } = useAppContext();
  const [query, setQuery] = useState<string | number>("");
  const debouncedSearch = useDebounce(query, 300);
  const handleChange = (e: any) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    setSearch(debouncedSearch);
  }, [debouncedSearch]);

  return (
    <form
      onSubmit={(e: any) => e.preventDefault()}
      action=""
      className={`${isShowSearchbar ? "w-full" : "w-0"
        } overflow-hidden md:hidden mb-3  absolute top-0 right-0  transition-all`}
    >
      <div className=" relative w-full border  border-gray-300  ">
        <input
          type="text"
          value={query}
          onChange={handleChange}
          className=" pl-12 pr-8 py-4 border outline-none text-sm w-full "
          placeholder="Search your favourite rewards"
        />
        <span className="absolute  right-3 top-[1rem]">
          <FiSearch className="text-xl text-filterHeading" />
        </span>
        <span
          data-testid="backbtn"
          onClick={() => {
            setIsShowSearchbar(false);
            setQuery("");
          }}
          className=" absolute left-2 top-[0.6rem] "
        >
          <IoIosArrowRoundBack className="font-medium text-4xl text-filterHeading" />
        </span>
      </div>
    </form>
  );
});

export default MobileSearchBar;

// search - left-4 top-[1.08rem]
